import Vue from 'vue'
import dayjs from 'dayjs'
import parseJwt from '@utils/parseJwt'
import jwt from 'jsonwebtoken'
import _get from 'lodash/get'
import oktaClient from '@utils/oktaClient'
import { Roles } from '@constants/okta'
import lambdaApi from '@services/lambdaApi'

import router from '@router/index.js'

import {
  USER_LOG_IN,
  USER_LOG_OUT,
  LOGIN_ERROR,
  RESET_LOGIN_ERROR,
  SET_SIGNIN_REDIRECT,
  SET_LOGOUT_INTERVAL_ID,
  CLEAR_INTERVAL,
  SET_ACCESS_TOKEN,
  SET_IS_AUTHENTICATED,
} from '@constants/mutations'

export const LOGOUT_INTERVAL = 'intervalId_logout'

const oneSecond = 1000
const oneMinute = oneSecond * 60
// const oneHour = oneMinute * 60

const validationIntervalTime = oneMinute * 5
// const validationIntervalTime = oneMinute * (4 / 60)

export default {
  state: {
    profile: null,
    accessToken: null,
    loginErrorMessage: '',
    tokenExpired: false,
    signInRedirect: false,
    [LOGOUT_INTERVAL]: false,
    validationIntervalTime,
    isAuthenticated: false,
    authClient: oktaClient,
  },
  mutations: {
    [USER_LOG_IN](state, payload) {
      const { profile, accessToken } = payload
      state.profile = profile
      state.accessToken = accessToken
      state.loginErrorMessage = ''
      state.tokenExpired = false
    },

    [USER_LOG_OUT](state, tokenExpired) {
      state.profile = null
      state.accessToken = null
      state[LOGOUT_INTERVAL] = false
      state.loginErrorMessage = ''
      state.tokenExpired = tokenExpired
    },

    [SET_SIGNIN_REDIRECT](state, path) {
      state.signInRedirect = path
    },

    [SET_IS_AUTHENTICATED](state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },

    [LOGIN_ERROR](state, payload) {
      const { message } = payload
      state.loginErrorMessage = message
    },

    [RESET_LOGIN_ERROR](state) {
      state.loginErrorMessage = ''
      state.tokenExpired = false
    },

    [SET_ACCESS_TOKEN](state, accessToken) {
      state.accessToken = accessToken
    }, // SET_ACCESS_TOKEN

    [SET_LOGOUT_INTERVAL_ID](state, intervalId) {
      state[LOGOUT_INTERVAL] = intervalId
    },

    [CLEAR_INTERVAL](state, intervalName) {
      state[intervalName] = clearInterval(state[intervalName])
    },
  },
  actions: {
    async logIn({ state, commit, dispatch }, { email, password }) {
      let transaction
      try {
        commit(RESET_LOGIN_ERROR)
        transaction = await state.authClient.signIn({
          username: email,
          password,
        })
        if (transaction.status !== 'SUCCESS') {
          return Promise.reject(transaction)
        } else {
          await dispatch('setUser', transaction)
          let firstPage = state.signInRedirect
            ? state.signInRedirect
            : '/dashboard'
          commit(SET_SIGNIN_REDIRECT, false)
          router.replace(firstPage)
          return Promise.resolve(transaction)
        }
      } catch (err) {
        commit(LOGIN_ERROR, err)
        return Promise.reject(Error(err))
      }
    }, // logIn
    async logOut({ commit, dispatch, rootState }, tokenExpired) {
      tokenExpired = tokenExpired || false
      router.push({
        path: '/',
      })
      await commit(SET_SIGNIN_REDIRECT, rootState.route.path)
      commit(USER_LOG_OUT, tokenExpired)
      commit(CLEAR_INTERVAL, LOGOUT_INTERVAL)
      await Vue.prototype.$auth.logout()
      dispatch('checkAuth')
    }, // logOut

    async checkAuth({ commit, getters }) {
      // returns a boolean value
      let authenticated = await Vue.prototype.$auth.isAuthenticated()
      // Only commit the mutation if there's a diff value retrieved
      if (authenticated !== getters.isAuthenticated) {
        commit(SET_IS_AUTHENTICATED, authenticated)
      }
    }, // checkAuth

    setAccessToken({ commit }, accessToken) {
      commit(SET_ACCESS_TOKEN, accessToken)
    }, // setAccessToken

    async setUser({ state, commit, dispatch }, transaction) {
      try {
        let Auth = Vue.prototype.$auth
        let user = await Auth.getUser()
        let profile = user
        let oktaId = _get(user, 'sub')
        profile.oktaId = oktaId
        profile.roles = await dispatch('fetchUserRoles', oktaId)
        let accessToken = await Auth.getAccessToken()
        const decoded = jwt.decode(accessToken, {
          complete: true,
        })
        // the decoded token's id (for comparision on okta server)
        profile.kid = decoded.header.kid

        user = { profile, accessToken }
        commit(USER_LOG_IN, user)
      } catch (error) {
        commit(USER_LOG_OUT)
      }
    }, // setUser

    async fetchUserRoles({ getters }, oktaId) {
      try {
        const response = await lambdaApi.post('getOktaUserRoles', { oktaId })
        return response.data
      } catch (error) {
        console.error('Error fetching user roles:', error)
        return []
      }
    }, // fetchUserRoles
  }, // actions
  getters: {
    /**
     * Auth / Login
     **/

    loginErrorMessage: (state) => state.loginErrorMessage,

    isAuthenticated: (state) => state.isAuthenticated,

    accessToken: (state) => state.accessToken,

    tokenExpiry: (state) => {
      let exp = state.accessToken
        ? parseJwt(state.accessToken).exp * 1000
        : false
      return exp ? dayjs(exp).format('h:mm:ssA') : false
    },

    firstName: (state) => !!state.profile && state.profile.given_name,
    lastName: (state) => !!state.profile && state.profile.family_name,

    //
    // Roles & Permissions
    //

    userRoles: (state) => (state.profile ? state.profile.roles : []),

    isForeman: (state, getters) =>
      getters.userRoles.includes(Roles.FOREMAN) || getters.isAdmin,

    isProjMgr: (state, getters) =>
      getters.userRoles.includes(Roles.PROJECT_MANAGER) || getters.isAdmin,

    isAccountant: (state, getters) =>
      getters.userRoles.includes(Roles.ACCOUNTANT) || getters.isAdmin,

    isAdmin: (state, getters) => getters.userRoles.includes(Roles.ADMIN),

    isDispatcher: (state, getters) =>
      getters.userRoles.includes(Roles.DISPATCHER),

    canViewScheduler: (state, getters) =>
      getters.userRoles.includes(Roles.SCHEDULER_VIEW) || getters.isAdmin,

    canEditScheduler: (state, getters) =>
      getters.userRoles.includes(Roles.SCHEDULER_EDIT) || getters.isAdmin,

    canAccessScheduler: (state, getters) =>
      getters.canViewScheduler || getters.canEditScheduler,

    canViewShiftItems: (state, getters, _, rootGetters) => {
      let canForemanView =
        getters.isForeman &&
        !_get(rootGetters['getLocation'], 'JOB_TYPE', []).includes('Fiber')

      return getters.isProjMgr || canForemanView || getters.isAdmin
    },

    canEditShiftItems: (state, getters) =>
      getters.isProjMgr || getters.isForeman || getters.isAdmin,

    canAddRemoveShiftItems: (state, getters) =>
      getters.isProjMgr || getters.isAdmin,

    getUserDisplayName: (state) => _get(state, 'profile.name', 'User Name'),
    getUserEmail: (state) => _get(state, 'profile.email', ''),
    getUserOktaId: (state) => _get(state, 'profile.oktaId'),
  },
}
